<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="col-12 content-header text-left">
        <h1 class="content-title">
          Recover Password
        </h1>
        <div class="content-subtitle">
          Please enter your email to reset your password. You’ll receive an
          email with instructions.
        </div>
      </v-col>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 py-0">
        <auth-form-layout
          submit-button-text="Reset Password"
          @submit-action="resetPasswordAndRedirect(item)"
        >
          <v-col class="col-12 mb-4">
            <form-input
              v-model="item.email"
              label="Email"
              :rules="rules.email"
              autocomplete="new-password"
            />
          </v-col>
        </auth-form-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import AuthFormLayout from '@/components/auth/AuthFormLayout'
import FormInput from '@/components/form/FormInput'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'ResetPassword',
  components: { AuthFormLayout, FormInput },
  data() {
    return {
      item: {
        email: null
      },
      rules: {
        email: [v => !!v || 'Email is required', v => emailValidation(v)]
      }
    }
  },
  head: {
    title: {
      inner: 'Recover Password'
    }
  },
  methods: {
    ...mapActions('authentication', ['resetPassword']),
    async resetPasswordAndRedirect(item) {
      await this.resetPassword(item)
      await this.$router.push({ name: 'resetPasswordComplete' })
    }
  }
}
</script>
<style scoped>
  .content-title {
    margin-top: 2rem;
  }
</style>